<template>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <form class="m-0" ref="formFilter" @submit.prevent="submitFilter">
          <div class="form-row">
            <div class="col-md-3">
              <div class="form-group mb-md-0">
                <label for="">Tag Tema</label>
                <v-select
                  ref="selectTema"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_subject"
                  placeholder="Digite o título"
                  :options="optionsTagTema"
                  @search="fetchTagsCategoryTema"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTema.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-md-0">
                <label for="">Período de cadastro</label>
                <flat-pickr
                  v-model="filters.rangeDate"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group mb-md-0">
                <label for="">Nome do professor</label>
                <input v-model="filters.title" class="form-control" />
              </div>
            </div>
            <div class="col-md-1">
              <label for="" style="visibility: hidden">Buscar</label>
              <button
                type="submit"
                :disabled="submitedFilter"
                class="btn btn-primary btn-block"
              >
                <div v-if="submitedFilter">
                  <b-spinner label="Loading..." small />
                </div>
                <div v-else>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer cursor"
                    size="16"
                  />
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <b-card no-body class="mb-0">
      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(full_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                rounded="sm"
                v-if="data.item.image"
                size="42"
                :src="data.item.image"
              />
              <b-avatar rounded="sm" v-else variant="light-secondary">
                <feather-icon icon="ImageIcon" />
              </b-avatar>
            </template>
            <span class="d-block text-nowrap">
              {{ data.item.full_name }}
            </span>
            <small>
              {{ toMaskCpf(data.item.cpf) }}
            </small>
          </b-media>
        </template>
       
        <template #cell(created_at)="{ item }">
          <span v-if="!item.updated_at">
            {{ item.created_at | datePT(true) }}
          </span>
          <span v-else>
            {{ item.updated_at | datePT(true) }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="
                $router.push({
                  name: 'teachers-show',
                  params: { uuid: data.item.uuid },
                })
              "
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="
                $router.push({
                  name: 'teachers-edit',
                  params: { uuid: data.item.uuid },
                })
              "
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-create-teacher"
      modal-class="modal-create"
      title="Cadastrar Professor"
      centered
      hide-footer
    >
      <form
        ref="formCreateUser"
        @submit.prevent="submitCreate"
        enctype="multipart/form-data"
      >
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome completo
              </label>
              <input
                type="text"
                id="full_name"
                v-model="item.full_name"
                minlength="5"
                name="full_name"
                class="form-control"
                :class="{ 'is-invalid': $v.item.full_name.$error }"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                E-mail
              </label>
              <input
                type="email"
                id="full-name"
                v-model="item.email"
                class="form-control"
                :class="{ 'is-invalid': $v.item.email.$error }"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                CPF
              </label>
              <input
                v-mask="['###.###.###-##']"
                type="tel"
                v-model="item.cpf"
                :class="{ 'is-invalid': $v.item.cpf.$error }"
                name="cpf"
                placeholder="000.000.000-00"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Celular
              </label>
              <input
                v-mask="['(##) #####-####']"
                type="tel"
                v-model="item.cellphone"
                name="cellphone"
                placeholder="(00) 00000-0000"
                class="form-control"
                :class="{ 'is-invalid': $v.item.cellphone.$error }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Tipo
              </label>
              <v-select :class="{ 'is-invalid': $v.item.type.$error }" label="title" :searchable="false" item-text="title" item-value="code" v-model="item.type" placeholder="Tipo"
                :options="optionsTypes">
                <span slot="no-options">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for=""> Foto do Perfil </label>
              <input
                style="padding: 0.4rem 0.5rem"
                type="file"
                id="file"
                ref="file"
                class="form-control"
                @change="handleFileUpload()"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mt-2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Tag(s) Tema
              </label>
              <v-select
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags"
                placeholder="Digite o título da tag"
                :options="optionsTagTema"
                @search="fetchTagsCategoryTema"
                :class="{ 'is-invalid': $v.item.tags.$error }"
              />
            </div>
          </div>
        </div>
        <div class="form-row justify-content-end mt-2">
          <div class="col-md-3">
            <button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn btn-outline-secondary btn-block"
              block
              @click="$bvModal.hide('modal-create-teacher')"
            >
              Fechar
            </button>
          </div>
          <div class="col-md-4">
            <button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn btn-success btn-block"
              type="submit"
              block
              :disabled="submited"
            >
              <div v-if="submited">
                <b-spinner small variant="light" /> Verificando...
              </div>
              <div v-else>Cadastrar</div>
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BFormRow,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import { maskCpf } from "@/_helpers/_urlHelper";

export default {
  components: {
    BSidebar,
    CustomPaginateTable,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCard,
    BRow,
    BForm,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BFormRow,
    vSelect,
  },
  directives: {
    Ripple,
  },
  validations: {
    item: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cpf: {
        required,
        minLength: minLength(11),
      },
      cellphone: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
        email,
      },
      tags: {
        required,
      },
      type: {
        required,
      },
    },
  },
  data: () => ({
    submited: false,
    submitedFilter: false,
    totalRows: 0,
    rowsTable: 0,
    item: {
      image: "",
      cpf: "",
      full_name: "",
      email: "",
      cellphone: "",
      type: "",
      tags: [],
    },
    tableColumns: [
      {
        key: "full_name",
        label: "Nome completo",
        sortable: true,
        thStyle: "width: 300px",
      },
      {
        key: "email",
        label: "E-mail",
      },
      {
        key: "created_at",
        label: "Cadastrado",
        sortable: false,
        class: "text-center",
        thStyle: "width: 210px",
      },
      {
        key: "actions",
        label: "",
        thClass: "text-center td-actions",
        thStyle: "width: 80px",
      },
    ],
    filters: {
      tag_subject: [],
      tag_contest: [],
      tag_timeline: [],
      rangeDate: null,
      title: "",
      currentPage: 1,
      perPage: 100,
    },
    optionsTagTema: [],
    optionsTagLinhaDoTempo: [],
    optionsTagConcurso: [],
    dataItems: [],
    optionsTypes: [
      {
        title: 'RPA',
        code: 'rpa',
      },
      {
        title: 'MEI',
        code: 'mei',
      },
    ],
  }),
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-teacher", () => {
      this.textSubmit = "Cadastrar";
      this.$bvModal.show("modal-create-teacher");
    });

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-teacher");
    this.$root.$off("paginate:update");
  },
  computed: {
    ...mapState("Teacher", ["teachers", "loading", "deleted"]),
    ...mapState("Tag", [
      "searchTagsTema",
      "searchTagsConcurso",
      "searchTagsLinhadoTempo",
    ]),
  },
  methods: {
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    submitFilter() {
      this.getData();
    },
    handleFileUpload() {
      this.item.image = this.$refs.file.files[0];
    },
    async fetchTagsCategoryConcurso(term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch("Tag/searchTags", {
          category_id: 2,
          term: term,
        });
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch(
          "Tag/searchTags",
          { category_id: 3, term: term }
        );
      }
    },
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    clearForm() {
      this.item = {
        cpf: "",
        full_name: "",
        email: "",
        cellphone: "",
        image: "",
      };
    },
    submitCreate() {
      this.$v.$touch();
     
      if (!this.$v.$error) {
        const formData = new FormData();
        formData.append("image", this.item.image);
        formData.append("full_name", this.item.full_name);
        formData.append("cpf", this.item.cpf);
        formData.append("cellphone", this.item.cellphone);
        formData.append("email", this.item.email);

        formData.append("tags", JSON.stringify(this.item.tags));

        this.$store
          .dispatch("Teacher/create", formData)
          .then((res) => {
            this.submited = true;
            this.getData();
            this.clearForm();
            this.$v.$reset;
            this.notifyDefault("success");

            this.$bvModal.show("modal-create-teacher");

            this.$router.push({
              name: 'teachers-edit',
              params: { uuid: res.data.uuid },
            })
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        tag_subject: this.filters.tag_subject
          ? this.filters.tag_subject.code
          : "",
        tag_contest: this.filters.tag_contest
          ? this.filters.tag_contest.code
          : "",
        tag_timeline: this.filters.tag_timeline
          ? this.filters.tag_timeline.code
          : "",
        rangeDate: this.filters.rangeDate ?? "",
        title: this.filters.title ?? "",
      };
      this.$store
        .dispatch("Teacher/all", {
          currentPage: this.filters.currentPage,
          perPage: this.filters.perPage,
          filters: filtersSearch,
        })
        .then(() => {
          if (this.teachers) {
            this.dataItems = this.teachers.data;
            this.rowsTable = this.teachers.data.length;
            this.totalRows = this.teachers.meta.total;
            this.filters.currentPage = this.teachers.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o professor caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Teacher/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "O usuário foi deletado com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
